import React, { Component } from "react"
import Header from "../Start/modules/SmallHeader"
import Footer from "../Start/modules/Footer"
import Content from "./modules/Content"
import { Link } from "react-router-dom";
import styled from "styled-components"
import Navigation from "../Start/modules/Navigation";

const text = styled.div`
    font-size: 32px;
    line-height: 38px;
`

export default class extends Component {
    render() {
        return <>
            <Navigation />
            <Header title="Technische Anlagenwartung" subTitle="Facility Management von A bis Z." image="/img/maintenance.jpg" />
            <Content>
                <div className="content">
                    <h3>Zuverlässige Technik. Sorglose Wartung.</h3>
                    <h2>scanmetrix bietet Ihnen ein breites Spektrum an herausragenden Dienstleistungen für Ihre TGA, angefangen von Wartungen und Instandhaltungen Ihrer technischen Brandschutz-, Raumlufttechnik-Anlagen und Hygieneinspektionen. Unsere Expertise und langjährige Erfahrung gewährleisten eine optimale Betreuung Ihrer Anlagen.</h2>
                    <div className="grid">
                        <div className="item">
                            <i className="fa-duotone fa-air-conditioner" />
                            <div className="right">
                                <p className="title">Lüftungs- und Klimaanlagen</p>
                                <p className="description">Unser Service umfasst die professionelle Wartung von Lüftungs- und Klimaanlagen, für die Sicherstellung eines reibungslosen Betriebes Ihrer Raumluft.</p>
                            </div>
                        </div>
                        <div className="item">
                            <i className="fa-duotone fa-sensor-fire" />
                            <div className="right">
                                <p className="title">RWA-Anlagen</p>
                                <p className="description">Wir bieten fach- & sachkundige Wartung und Instandhaltung von RWA-Anlagen an, um für einen potenziellen Brandfall bestmöglich vorgesorgt zu sein.</p>
                            </div>
                        </div>
                        <div className="item">
                            <i className="fa-duotone fa-window-frame" />
                            <div className="right">
                                <p className="title">Tageslichtersysteme</p>
                                <p className="description">Unser Service umfasst die zuverlässige Wartung und Instandhaltung von Tageslichtsystemen, um eine optimale Lichtqualität und Energieeffizienz zu gewährleisten.</p>
                            </div>
                        </div>
                        <div className="item">
                            <i className="fa-duotone fa-fire-hydrant" />
                            <div className="right">
                                <p className="title">Löschwassereinrichtungen</p>
                                <p className="description">Wir kümmern uns um die fachgerechte Wartung und Instandhaltung Ihrer Löschwassereinrichtungen, um im Ernstfall eine effektive Brandbekämpfung zu gewährleisten.</p>
                            </div>
                        </div>
                        <div className="item">
                            <i className="fa-solid fa-door-open" />
                            <div className="right">
                                <p className="title">Feuerschutzabschlüsse</p>
                                <p className="description">Unsere Experten sorgen für die regelmäßige Wartung und Instandhaltung Ihrer Feuerschutzabschlüsse, um deren einwandfreie Funktionsfähigkeit im Brandfall sicherzustellen.</p>
                            </div>
                        </div>
                        <div className="item">
                            <i className="fa-solid fa-fire-extinguisher" />
                            <div className="right">
                                <p className="title">Feuerlöscher (tragbare & fahrbare)</p>
                                <p className="description">Unsere professionelle Wartung und Instandhaltung umfasst sowohl tragbare als auch fahrbare Feuerlöscher, um deren Funktionsfähigkeit im Ernstfall sicherzustellen.</p>
                            
                            </div>
                            </div>

                            <div className="item">
                            <i className="fa-duotone fa-vent-damper" />
                            <div className="right">
                                <p className="title">Brandschutzklappen</p>
                                <p className="description">Die Wartung und Instandhaltung Ihrer Brandschutzklappen wird von unseren Fachleuten gewissenhaft durchgeführt, um Ihre Sicherheitssysteme in einwandfreiem Zustand zu halten.</p>
                            </div>
                        </div>

                        <div className="item">
                            <i className="fa-duotone fa-broom" />
                            <div className="right">
                                <p className="title">Reinigung von RLT-Anlagen</p>
                                <p className="description">Wir gewährleisten die fachgerechte Reinigung und Instandhaltung raumlufttechnischer Anlagen, um eine optimale Luftqualität in Ihren Räumlichkeiten sicherzustellen.</p>
                            </div>
                        </div>

                        <div className="item">
                            <i className="fa-solid fa-vent-damper" />
                            <div className="right">
                                <p className="title">Hygieneinspektion gem. VDI 6022</p>
                                <p className="description">Unsere Wartung und Instandhaltung umfasst die Lüftungskanalreinigung und Hygieneinspektion nach den Richtlinien der VDI 6022, um höchste Hygienestandards zu gewährleisten.</p>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "5rem"}}>
                        <h1>Geprüfte Qualität für Ihre Sicherheit</h1>
                        <div style={{marginTop: "2rem",}}>
                            <img src="/img/bvbf.png" />
                        </div>
                        
                    </div>
                </div>
                
            </Content>
            <Footer />
        </>
    }
}
