import React, { useEffect, useState } from "react"
import StyledTeam from "./styled/StyledTeam"
import { Link } from "react-router-dom"
import { Carousel } from '@trendyol-js/react-carousel';
import styled from "styled-components"

const StyledArrowButton = styled.div`
    width: 64px;
    height: 64px;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3b97d3;
    font-size: 24px;
    transition: all 0.3s;
    position: relative;
    top: calc(50% - 32px);
  
    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
`

const people = [
    { key: "udo", name: "Udo Witte", position: "Gesellschafter-CEO" },
    { key: "volker", name: "Volker Schreiber", position: "Gesellschafter, Vertrieb" },
    { key: "jack", name: "Jack Hull", position: "Gesellschafter, Digitales" },
    //{ key: "dirk", name: "Dirk Brinkmann", position: "Gesellschafter" },
    { key: "sezai", name: "Sezai Yildirimer", position: "Gesellschafter, Bauwesen" },
    { key: "andrea", name: "Andrea Holtappels", position: "Leiterin Innendienst" },
    { key: "chaymae", name: "Chaymae Sealiti", position: "Sachbearbeiterin" },
    { key: "aysu", name: "Aysu Öztürk", position: "Facility Managerin" },
    //{ key: "marco", name: "Marco Witte", position: "UX Manager" },
    //{ key: "felix", name: "Felix Flügel", position: "Controlling" },
    //{ key: "felixp", name: "Felix Pistner", position: "Software Projektmanager" },
    { key: "rohit", name: "Rohit Chandani", position: "Softwareentwickler" },
    { key: "lukas", name: "Lukas Groß", position: "Softwareentwickler" },
    { key: "dennis", name: "Dennis Romahn", position: "Leiter Außendienst" },
    { key: "bastian", name: "Bastian Holzer", position: "Außendienst Region Süd" },
    { key: "frank", name: "Frank Güse", position: "Außendienst" },
    { key: "andreas", name: "Andreas Pätzold", position: "Außendienst" },
    { key: "benjamin", name: "Benjamin Mielke", position: "Außendienst" },
    { key: "abbas", name: "Abbas Jafari", position: "Außendienst" },
    { key: "vinko", name: "Vinko Pandza", position: "Außendienst" },
    { key: "konstantinus", name: "Konstantinus Miaris", position: "Außendienst" },
    { key: "carsten", name: "Carsten Gerhardt", position: "Außendienst" },
]

export default function () {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return <StyledTeam>
        <div className="container">
            <div className="title">
                <h3>Persönlich.</h3>
                <h2>Unser starkes Team</h2>
                <h4>
                    Denn keiner von uns ist so klug wie wir alle. scanmetrix legt großen Wert auf Familiarität, Zusammenhalt und Fairheit - überzeugen Sie sich selbst von diesen strahlenden Gesichtern!
                </h4>
            </div>
            <div className="people">
                <Carousel dynamic key={windowSize[0]} autoSwipe={1500} responsive={true} show={windowSize[0] >= 1280 ? 4 : (windowSize[0] >= 1024 ? 3 : (windowSize[0] >= 768 ? 2 : 1))} slide={1} swiping={true} leftArrow={<StyledArrowButton><i className="fas fa-arrow-left" /></StyledArrowButton>} rightArrow={<StyledArrowButton><i className="fas fa-arrow-right" /></StyledArrowButton>} infinite={true}>
                    {people.map(person =>
                    <div className="person" key={person.key}>
                        <img src={`/img/team/${person.key}.png`} />
                        <div className="name">
                            <p>{person.name}</p>
                            <p>{person.position}</p>
                        </div>
                    </div>)}
                </Carousel>
            </div>
            <div className="career">
                <h2><i className="fa-duotone fa-hand-wave" /> Wir suchen Verstärkung!</h2>
                <h3>Dein Gesicht fehlt noch in der Liste?<br />Schaue dir unsere Stellenanzeigen an oder bewerbe dich initiativ.</h3>
                <div className="kununu">
                    <a href="https://kununu.com/de/scanmetrixfm1?utm_medium=affiliate&utm_source=widget&utm_content=widget_score_logo_small&rfr=affiliate_widget"
                       rel="nofollow noopener" target="_blank"><img alt="kununu widget"
                                                                    src="https://widgets.kununu.com/widget_score_logo_small/profiles/32877b4d-6c9f-4867-a881-1944eda95080"/></a>
                    {/*<div className="stars">
                        <i className="fas fa-heart" />
                        <i className="fas fa-heart" />
                        <i className="fas fa-heart" />
                        <i className="fas fa-heart" />
                        <i className="fa-duotone fa-heart-half" />
                    </div>
                    <p>4.9 von 5 Sternen auf <a href="https://www.kununu.com/de/scanmetrixfm1/">Kununu</a></p>*/}
                </div>
                <Link to="/careers" className="button"><i className="fas fa-arrow-right"/> Zu den Stellenanzeigen</Link>
            </div>
        </div>
    </StyledTeam>
}
